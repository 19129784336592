.collect_wrapper
    width: 100%
    border-radius: 4px
    height: 8px
    background: red
    line-height: 0
    margin: 6.5px 0 8.5px 0
    background: #1976d2
    overflow: hidden
    position: relative

.collect_value
    background: green
    width: 0
    height: 8px
    display: inline-block
    transition: all 0.3s
    background: #cddc39

.link
    color: #333
    font-size: small