$pd: 24px

.actions
    position: fixed
    z-index: 2
    bottom: 0
    left: 0
    right: 0
    height: 72.5px
    background: #fff
    box-shadow: -1px 0px 8px #d1d1d1
    transition: all 0.3s
    display: flex
    align-items: center
    justify-content: center
    svg
        margin-right: 5px

.actions_container
    display: flex
    justify-content: center

.preTable
    top: 64px
    height: 66px
    z-index: 2
    position: sticky
    background-color: rgb(255, 255, 255)
    width: calc(100% + 49px)
    margin-left: -25px

.ul
    margin: 0
    list-style: none
    padding: 0
    overflow: hidden
    li
        color: #79817A
        font-size: 13px
        line-height: 18px
        white-space: nowrap
        overflow: hidden
        text-overflow: ellipsis
        &:first-child
            color: #212121
            font-size: 0.875rem
            //margin-bottom: 4px

.note
    color: #333
    white-space: pre-line
    font-size: 13px
    line-height: 18px
    max-height: calc(31px * 3)
    overflow-y: auto

.status
    background-color: #d0e7dc
    padding: 4px 8px
    color: #50a57c
    border-radius: 5px
    display: inline-block
    margin-top: 8px
    text-align: center!important
    font-size: 14px
    font-weight: 500

.button
    border: none
    background-color: transparent
    border-radius: 5px
    padding: 8px 14px
    color: #79817A
    font-weight: 500
    box-shadow: 0 2px 7px rgba(0, 0, 0, 0.15)
    cursor: pointer
    transition: all 0.2s
    &:hover
        box-shadow: 0 2px 7px rgba(0, 0, 0, 0.2)

.inputCopy
    position: absolute
    z-index: -1

.buttonLarge
    background-color: #fe753e
    text-align: center
    color: #fff
    font-size: 20px
    padding: 10px
    overflow: hidden
    border-radius: 10px
    font-weight: bold
    display: flex
    justify-content: center
    align-items: center
    cursor: pointer
    &:hover
        opacity: 0.8

.pdr
    padding-right: $pd

.tabWrapper
    flex: 1
    overflow-y: auto
    &::-webkit-scrollbar
        width: 3px
        border-radius: 2.5px
    &::-webkit-scrollbar-track
        background: transparent
        border-radius: 2.5px
    &::-webkit-scrollbar-thumb
        background: transparent
        border-radius: 2.5px
    &::-webkit-scrollbar-thumb:hover
        background: transparent
    &:hover
        &::-webkit-scrollbar
            width: 3px
            border-radius: 2.5px
        &::-webkit-scrollbar-track
            background: #fff
            border-radius: 2.5px
        &::-webkit-scrollbar-thumb
            background: #ddd
            border-radius: 2.5px
        &::-webkit-scrollbar-thumb:hover
            background: #aabac1

.quickView
    background: #f4f7f9
    top: 58px
    margin-top: -29px
    position: sticky
    padding-top: 29px
    padding-left: $pd
    padding-right: $pd
    padding-bottom: 0
    height: calc(100vh - 58px)
    //min-height: calc( 100vh - 103.5px + 32px)
    display: flex
    flex-direction: column

.select
    width: 70%
    margin-top: 40px
    margin-bottom: 60px

.sticky
    position: sticky
    bottom: 12px
    z-index: 2
    width: 100%

.copy
    background: #e8eef2
    padding: calc($pd / 2)
    border-radius: 15px

.info
    display: grid
    grid-template-columns: auto 1fr
    font-size: 14px
    border-bottom: 1px dashed #aabac1
    padding: 8px 0
    div
        margin-bottom: 12px
    div:nth-child(odd)
        color: #79817A
    div:nth-child(even)
        text-align: right
        padding-left: 8px
        overflow: hidden
        text-overflow: ellipsis
    &:last-child
        border-bottom: none

.hint
    color: #79817A
    font-size: 14px
    margin: 8px 0
    line-height: 24px
    svg
        height: 18px
        vertical-align: -4px
        margin-right: 5px

.realTable
    display: grid
    grid-template-columns: repeat(3, auto) 50px
    grid-template-rows: [header-start] 50px [header-end row-start] auto [row-end]
    &.c5
        grid-template-columns: 2.5fr 2.5fr 2.5fr 2.5fr 50px
    &.c9
        grid-template-columns: 75px repeat(7, auto) 120px
        .rowWrapper
            & > div
                display: flex
                align-items: center
    .heading
        font-weight: 500
        grid-row: header
        background: #fff
        position: sticky
        top: 80px
        font-size: 14px
        color: #212121
        z-index: 2

    .rowWrapper
        display: contents
        & > div
            border-bottom: 1px solid #f4f7f9
            padding: 16px 16px 16px 0
            position: relative
            overflow: hidden
            &:first-child, &:last-child
                overflow: initial
        &:hover > div, &.hover > div
            background: #f4f7f9
            &:first-child
                &::before
                    content: ""
                    position: absolute
                    left: -25px
                    border-radius: 20px 0 0 20px
                    width: 25px
                    top: -1px
                    bottom: -1px
                    background: #f4f7f9
            &:last-child
                &::before
                    content: ""
                    position: absolute
                    right: -$pd
                    width: $pd
                    top: -1px
                    bottom: -1px
                    background: #f4f7f9

    & > div
        border-bottom: 1px solid #f4f7f9
        padding: 16px 8px 16px 0

.oversize
    width: calc( 100% + 25px )
    position: relative
    left: -25px

.rowContainer
    position: absolute
    left: -25px
    border-radius: 20px 0 0 20px
    width: 25px
    top: -1px
    bottom: -1px
    background: #f4f7f9
.rowWrapperOutside
    position: relative
    left: 25px
    display: grid
    width: calc( 100% - 25px )
    //grid-template-rows: [header-start] 50px [header-end row-start] auto [row-end]
    grid-template-columns: 2.5fr 2.5fr 2.5fr 2.5fr 50px
    border-bottom: 1px solid #f4f7f9
    padding: 16px 0px 16px 0
    max-height: 141px
    &:hover
        background: #f4f7f9
        &::before
            content: ""
            position: absolute
            left: -25px
            border-radius: 20px 0 0 20px
            width: 25px
            top: -1px
            bottom: -1px
            background: #f4f7f9
    & > div
        position: relative
        overflow: hidden
.wrapperFilter
    padding: 16px 0
    border: 1px solid #f4f7f9
    border-right: none
    border-left: none
    margin-top: 24px
    //background-color: rgba(0, 0, 0, 0.01)

.note_gh
    font-style: italic
    color: #f44336
    font-size: 13px

@media (min-width: 0) and (max-width: 599px)
    .preTable
        z-index: -1

@media (min-width: 0) and (max-width: 1279px)
    $pd: 0
    .pdr
        padding-right: $pd
    .quickView
        padding-left: $pd
        padding-right: $pd
    .copy
        padding: calc($pd / 2)
    .realTable
        .rowWrapper
            &:hover > div, &.hover > div
                &:last-child
                    &::before
                        right: -$pd
                        width: $pd

@media (min-width: 1920px)
    $pd: 48px
    .info
        padding: 16px 0
    .pdr
        padding-right: $pd
    .quickView
        padding-left: $pd
        padding-right: $pd
        padding-bottom: 29px
    .sticky
        bottom: 30px
    .preTable
        width: calc(100% + 73px)
    .rowWrapperOutside
        grid-template-columns: 2.1fr 2.7fr 2.5fr 2.7fr 50px
    .realTable
        &.c5
            grid-template-columns: 2.1fr 2.7fr 2.5fr 2.7fr 50px
        .rowWrapper
            &:hover > div, &.hover > div
                &:last-child
                    &::before
                        right: -$pd
                        width: $pd
