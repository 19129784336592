.container {
    background-color: #3da495;
    padding: 8px 0;
    color: #fff;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 4;
}

.wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
    color: rgba(0, 0, 0, 0.54);
}

.bank {
    margin: 0 -16px;
    padding: 8px 16px;
    font-weight: 500;
    font-size: 12px;
    border-top: 1px dashed #ddd;
    border-bottom: 1px dashed #ddd;
}

.hasAlert {
    position: absolute;
    top: 0;
    right: 0;
    height: 8px;
    width: 8px;
    border-radius: 4px;
    background-color: #ec5252;
    border: 1px solid #3da495;
}

.space {
    height: 100px;
}

.home {
    min-width: 124.22px;
}

.home:hover {
    opacity: 0.8;
    text-decoration: none;
}

.logoAndSearch {
    display: flex;
    align-items: center;
}

.logoAndSearch a {
    color: #fff;
    text-decoration: none;
}

.search {
    margin-left: 32px;
    border-radius: 100px;
    padding: 10px 20px;
    border: none;
    outline: none;
    color: #fff;
    width: 450px;
    text-transform: uppercase;
}

.search[disabled]
{
    cursor:not-allowed;
}

.search::placeholder {
    color: rgba(255,255,255,0.7);
    text-transform: initial;
}

.search:focus + label, .search:hover + label {
    color: #fff;
}

.username {
    margin: 0 0px 0 10px;
    font-size: 13px;
    text-transform: capitalize;
}

.avatarAlert {
    display: flex;
    justify-content: flex-end;
    flex: 1;
    align-items: center;
}

.search + label {
    position: absolute;
    top: 8px;
    right: 20px;
    cursor: text;
    color: rgba(255,255,255,0.7);
}

.highLight {
    user-select: none;
    background: #fff;
    color: #3da495;
    width: 25px;
    height: 25px;
    border-radius: 12.5px;
    font-weight: bold;
    margin-right: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
}