:root {
    --done: #9c27b0;
    --undone: #ffc107;
    --msg: #f44336;
}

.ticket_new_msg {
    position: absolute;
    font-size: small;
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 3px;
    padding: 0 5px;
    color: #3f51b5;
    top: -20px;
    cursor: pointer;
}

.msg-close {
    display: inline-flex;
    align-items: center;
    padding: 0 5px;
}

.ticket_typing_msg {
    position: absolute;
    font-size: srgba(168, 161, 161, 0.431);
    background: #ffffff6e;
    border-radius: 3px;
    padding: 0 5px;
    color: #3d85c6;
    top: -20px;
    right: 18px;
    font-size: small;
}

.msg-unread {
    background: var(--msg);
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    border-radius: 12px;
    font-size: small;
    font-weight: 500;
    padding: 0 5px;
    height: 18px;
    display: inline-flex;
    margin-left: 5px;
}

.ticket_undone {
    color: var(--undone);
}

.ticket_done {
    color: var(--done);
}

.dotUndone {
    width: 8px;
    height: 8px;
    border-radius: 2.5px;
    background: var(--undone);
    display: inline-block;
}

.dotDone {
    width: 8px;
    height: 8px;
    border-radius: 2.5px;
    background: var(--done);
    display: inline-block;
}

.ticket_active_wrapper {
    position: fixed;
    bottom: 0;
    right: 48px;
    display: inline-flex;
    flex-direction: row-reverse;
    align-items: flex-end;
    z-index: 1;
}

.ticket_active {
    margin-left: 16px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    width: 300px;
    text-align: center;
    border: 1px solid #ddd;
    transition: all.2s;
    overflow: hidden;
}

.ticket_title {
    font-weight: 500;
    background: #fff;
    color: #000;
    padding: 8px 16px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.ticket_title:hover {
    background: #f2f2f2;
}

#highest_ticket_container {
    position: relative;
    margin-right: 8px;
}

#highest_ticket_container * {
    transition: all .2s;
}

#highest_ticket_container>div:nth-child(3) {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0 5px;
    height: 18px;
    background: var(--msg);
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    border-radius: 12px;
    font-size: small;
    font-weight: 500;
}

#highest_ticket_container>div:nth-child(2) {
    position: absolute;
    top: 57px;
    background: #fff;
    width: 400px;
    border: 1px solid rgba(0, 0, 0, 0.12);
    right: 0;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0px 1px 5px #e5e5e5;
    opacity: 0;
    visibility: hidden;
    cursor: pointer;
    z-index: -1;
    max-height: 500px;
    overflow: auto;
}

#highest_ticket_container>div:nth-child(2).active {
    opacity: 1;
    visibility: visible;
    z-index: 1;
}

.ticket_item {
    padding: 10px 15px;
    border-bottom: 1px solid #f1f1f1;
    color: #333;
    line-height: 20px;
}

.ticket_item:hover {
    background: #f1f1f1;
}

.ticket_info {
    text-align: left;
    font-size: small;
    padding: 5px 16px;
    border-bottom: 1px dashed #ddd;
    color: #000;
}

.ticket_chat_wrapper {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    height: 400px;
    transition: height .2s;
    background: #fff;
}

.ticket_sender {
    font-size: 10px;
    margin-right: 5px;
}

.ticket_chat_wrapper .bubble {
    padding: 5px 10px;
    font-size: 15px;
    border-radius: 15px;
    display: inline-block;
    margin: 5px;
    max-width: 200px;
    text-align: left;
    margin-bottom: 0;
    overflow: hidden;
    text-overflow: ellipsis;
}

.bubbleWrapper1 {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: 5px;
}

.bubbleWrapper2 {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    margin-bottom: 5px;
}

.ticket_chat_wrapper .user1 {
    background: #3d85c6;
}

.ticket_chat_wrapper .user2 {
    background: #8bc34a;
}

.ticket_handler {
    color: #637b88;
    display: flex;
    justify-content: space-between;
}

.ticket_chat {
    display: flex;
    flex-direction: column;
}

.ticket_msg {
    flex: 1
}

.ticket_sender {
    display: flex;
    padding: 0 5px;
    border-radius: 5px;
    color: #333;
}

.ticket_typing {
    color: #333;
    font-size: small;
    position: absolute;
    top: -25px;
    padding: 0 5px;
    background: #f0faff;
}

@media (max-width: 599px){
    #highest_ticket_container>div:nth-child(2) {
        position: fixed;
        top: 51px;
        background: #fff;
        width: unset;
        border: 1px solid rgba(0, 0, 0, 0.12);
        border-radius: 8px;
        overflow: hidden;
        box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
        opacity: 0;
        visibility: hidden;
        cursor: pointer;
        z-index: -1;
        max-height: 500px;
        overflow: auto;
        right: 8px;
        left: 8px;
    }
    .ticket_active_wrapper {
        bottom: 55px;
        right: 0;
    }
}