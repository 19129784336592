
*
    -webkit-appearance: none
    box-sizing: border-box

.ReactVirtualized__Grid__innerScrollContainer
    &::-webkit-scrollbar
        display: none
.ReactVirtualized__Grid.ReactVirtualized__List
    &::-webkit-scrollbar
        display: none
.warehouse_cog
    background: rgb(254, 117, 62)
    display: flex
    align-items: center
    justify-content: center
    color: #fff
    width: 50px
    border-radius: 4px
    margin-left: 8px
    border: none
    outline: none
    cursor: pointer
    &:hover
        opacity: 0.9

.live
    background: #ddd
    color: #fff
    border-radius: 50px
    padding: 0 16px
    margin-left: 10px
    font-size: 14px
    display: flex
    align-items: center
    overflow: hidden
    user-select: none
    &.active
        background: #f44336
    div
        animation: live 1s infinite alternate

@keyframes live
    0%
        opacity: 0.6
    100%
        opacity: 1

.empty
    line-height: 24px
    border: 1px dashed #eee
    border-left: none
    border-right: none
    padding: 16px 0
    position: relative
    z-index: 2

.templateScroll
    white-space: nowrap
    overflow-x: auto
    text-align: right
    &::-webkit-scrollbar
        height: 4px
    &::-webkit-scrollbar-thumb
        background: #fff
        border-radius: 10px
    &:hover
        &::-webkit-scrollbar
            //width: 5px
            height: 4px
        &::-webkit-scrollbar-thumb
            background: #ddd
            border-radius: 10px

.result-searched-yellow
    position: absolute
    white-space: nowrap
    z-index: 3
    color: orange
    font-size: 15px
    bottom: -20px
    &::before
        content: ""
        width: 8px
        height: 8px
        display: inline-block
        background: orange
        vertical-align: 1px
        margin-right: 6px
    & b
        font-weight: 500

.result-searched
    white-space: nowrap
    position: absolute
    z-index: 3
    color: #aab9c1
    font-size: 15px
    bottom: -20px
    &::before
        content: ""
        width: 8px
        height: 8px
        display: inline-block
        background: #aabac1
        vertical-align: 1px
        margin-right: 6px
    & b
        font-weight: 500

.copy
    cursor: copy

.dropzone
    text-align: center
    border: 1px dashed #ddd
    padding: 16px
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis

.loadingHint
    color: #aabac1
    padding-left: 24px

.react-datepicker-popper
    z-index: 4

.react-datepicker__close-icon::after
    background: #aabac1

.detail_item
    right: -12px
    top: 50px
    color: #aab9c1
    background: #fff
    width: 25px
    height: 25px
    justify-content: center
    border-radius: 15px
    display: flex
    border: 1px solid
    align-items: center
    position: absolute

a
    text-decoration: none
    color: #1976d2
    &:hover
        text-decoration: underline

.link
    cursor: pointer
    &:hover
        opacity: 0.8

.sps_input_wrapper
    margin: 8px 0
    width: 100%

.pageWrapper
    display: flex
    justify-content: center
    margin-top: 32px

.o-hidden
    display: none

.react-datepicker__close-icon
    &:hover
        &::after
            color: #333
    &::after
        color: #aabac1
        border: 1px solid
        background: transparent

.unread_notifications
    position: absolute
    width: 10px
    height: 10px
    background: red
    border: 1px solid #fff
    right: 16px
    border-radius: 5px
    top: 18px

@media (max-width: 599px)
    .xs-w100
        width: 100%
    .xs-block
        display: block
    .xs-hidden
        display: none

@media (max-width: 1023px)
    .sm-hidden
        display: none

@media (min-width: 1024px)
    .md-hidden
        display: none

@media (min-width: 1920px)
    .xl-block
        display: block

@media (min-width: 1921px)
    #root[aria-hidden]
        #fixedHeader
            padding-right: 15px
