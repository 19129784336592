.bg
    background: #f2f6ff
    position: fixed
    inset: 0

.wrapper
    position: relative
    z-index: 1
    height: 100vh
    display: flex
    align-items: center
    padding: 0 10px
    padding-top: 40px
    flex-direction: column

.loginForm
    background: #fff
    padding: 32px
    border-radius: 8px
    text-align: center
    width: 450px
    max-width: 100%
    box-shadow: 0px 2px 8px 1px #1692d014
    margin-bottom: 24px

.cookies
    color: #7889A4
    font-size: 14px
    text-align: center
    line-height: 28px
    padding-bottom: 20px
    a 
        color: inherit

.logo
    color: #19488d
    font-size: 32px
    font-weight: 500

.slogan
    margin-top: 12px
    margin-bottom: 32px
    color: #8297b6

@media (max-width: 599px)
    .cookies
        font-size: 12px
    .loginForm
        padding: 24px
