.wrapper
    position: sticky
    top: 93px
    .title
        color: #79817A
        font-size: 12px
        margin-bottom: 15px
        padding-left: 16px
        @media screen and ( min-width: 1920px)
            padding-left: 40px
    .ul
        &>a
            display: flex
            align-items: center
            text-decoration: none
            color: #424242
            font-size: 14px
            padding: 10px 0
            border-left: 2px solid #fff
            padding-left: 16px
            border-top-right-radius: 5px
            border-bottom-right-radius: 5px
            @media screen and ( min-width: 1920px)
                padding-left: 40px
            &[class="active"]
                border-left: 2px solid
                background: #f5fafa
                font-weight: 500

            &[class="active"] .icon
                color: #fff

            &:hover
                background: #f5fafa

.desktop
    position: absolute
    left: -24px
    width: 80%
    z-index: 1
    height: 100%
    @media screen and ( min-width: 1920px)
        left: -48px
    .img
        position: relative
        right: -16px
        width: 135px
        height: 135px
        border-radius: 50%
        box-shadow: 0px 0px 30px 2px rgba(0, 0, 0, 0.3)
        margin-bottom: 70px
        background-color: #fff
        div[data="slide"]
            background-size: cover
            width: 135px
            height: 135px
            position: absolute
            transition-property: opacity
            transition-duration: 3s
            border-radius: 100px
        @media screen and ( min-width: 1920px)
            right: -40px
