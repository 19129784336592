.wrapper
    width: 45px
    height: 45px
    background: #ddd
    border-radius: 32%
    justify-content: center
    align-items: center
    display: flex
    margin-right: 18px

.title 
    color:  rgb(97, 97, 97)
    font-size: 12px
    margin-bottom: 6px
    letter-spacing: 1px

.count
    color: #212121
    font-size: 20px
    font-weight: 700

.showMoneyWrapper
    position: absolute
    right: -8px
    @media screen and ( max-width: 599px )
        right: 8px

.separator
    margin: 29.5px 0
    height: 1px
    background: #eee
    position: absolute
    width: calc( 100% - 48px )
    left: 24px
    @media screen and ( max-width: 599px )
        width: calc( 100% - 32px )
        left: 16px
    @media screen and ( min-width: 1920px)
        width: calc( 100% - 96px  )
        left: 48px