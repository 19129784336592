.uploadContainer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-rows: auto;
    grid-auto-columns: auto;
    grid-column-gap: 10px;
    grid-row-gap: 20px;
    margin-top: 8px;
}

.uploadContainer > * {
    border: 1px dashed rgb(221, 221, 221);
    height: 95px;
    border-radius: 8px;
    cursor: pointer;
    transition-duration: .3s;
    transition: all.3s;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.uploadContainer > * > span > svg {
    position: absolute;
    bottom: -10px;
    right: 4px;
    background: #dfe5e7;
    border-radius: 50px;
    color: #686868;
    padding: 3px;
    width: 20px;
    height: 20px;
}

.uploadContainer > *:hover {
    background-color: #f9f9f9;
    border-color: #333;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}